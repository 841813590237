// Use This URL for testing
const URL = "adarsh.bfiinfotech.co.in";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "Bank of Maharashtra, P-40,MIDC Gokul Shirgaon, Kolhapur, Kolhapur",
  "Bank A/c. No.": "60373799351",
  "IFSC Code": "MAHB0001575",
};
export const GST_NUMBER = "";

export const DISABLED_MODULE =["Summary Report","Courier Register","Expenses","Master In/Out","Client Complaints","Client Feedback","Audit","Document Upload","User","Purchase"
,"DUC Master","Department","Request Form","Attendance","MarkAttendance","CoordinatesAddress","Settings","Datasheet Template","Discipline","OfflineMode","WCR Report","Supplier Invoice","Department Analytics","Training Resources"]; 

export const DEVICE_TYPE = ["desktop"];
